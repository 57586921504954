import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import * as Yup from 'yup';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import { CheckInput, DateInput, DropdownInput, RadioInput, TextInput } from './FormikInputs.js';


function FormController(props) {
  const [filename, setFilename] = useState('');

  const modify = props.data !== undefined;

  let initialValues = modify ? props.data : {};
  let validationSchema = {};

  Object.entries(props.fields).forEach(([name,field]) => {
    if (modify) {
      if ('validation' in field) validationSchema[name] = field.validation;
    } else {
      if ('initial' in field) {
        initialValues[name] = field.initial;
      } else {
        return null;
      }
      if ('validation' in field) validationSchema[name] = field.validation;
    }
  });

  const components = Object.entries(props.fields).map(([name,field],index) => {
    if (!(modify) && !('initial' in field)) return null;
    switch(field.type) {
      case 'check':
        return (
          <CheckInput
            key={index}
            label={field.label}
            name={name}/>
        );
      case 'date':
        return (
          <DateInput
            key={index}
            label={field.label}
            name={name} />
        );
      case 'dropdown':
        return (
          <DropdownInput
            key={index}
            label={field.label}
            name={name}
            options={field.options}
            condItem={'condItem' in field ? field.condItem : undefined} />
        );
      case 'radio':
        return (
          <RadioInput
            key={index}
            label={field.label}
            name={name}
            options={field.options}
            condItem={'condItem' in field ? field.condItem : undefined}
            condValues={'condValues' in field ? field.condValues : undefined}
            userItem={'userItem' in field ? field.userItem : undefined} />
        );
      case 'number':
        return (
          <TextInput
            key={index}
            label={field.label}
            name={name}
            type='number'
            step='0.01'
            condItem={'condItem' in field ? field.condItem : undefined}
            condValues={'condValues' in field ? field.condValues : undefined}/>
        );
      case 'text':
        return (
          <TextInput
            key={index}
            label={field.label}
            name={name}
            type='text'
            condItem={'condItem' in field ? field.condItem : undefined}
            condValues={'condValues' in field ? field.condValues : undefined}/>
        );
      default:
        return null;
    }
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup.object(validationSchema)}
      onSubmit={props.submit}>
      <FormikForm>
        {components}
        { props.upload &&
          <Form.Group>
            <Form.Label>{props.uploadLabel}</Form.Label>
            <UploadController setFilename={setFilename} />
          </Form.Group>
        }
        <div className="mt-2">
          { !props.upload &&
            <Button type="submit" className="mr-2">
              {props.submitLabel}
            </Button>
          }
          { props.upload &&
            <Button type="submit" disabled={filename === ''} className="mr-2">
              {props.submitLabel}
            </Button>
          }
          { props.back !== undefined &&
            <Button onClick={props.back}>
              Cancel
            </Button>
          }
        </div>
      </FormikForm>
    </Formik>
  );
}


function UploadController(props) {
  return (
    <FilePond
      allowMultiple={false}
      onprocessfile={(error,file) => {
        if (error) {
          alert('Filepond process error.');
          return;
        }
        props.setFilename(file.serverId);
      }}
      onremovefile={(error,file) => {
        if (error) {
          alert('Filepond remove error.');
          return;
        }
        props.setFilename('');
      }}
      server="/api/files"/>
  );
}


export { FormController };
