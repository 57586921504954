import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import ReactTable from './ReactTable.js';
import { FormController } from './componentsForm.js';
import { LoadingSpin, BannerStore, DropdownSelect, DropdownFilter, DropdownYear } from './componentsUtil.js';
import UserContext from './UserContext.js';
import { useViews, useFilters } from './hooksOther.js';
import { useDelete, useDeleteItem, useGetCollection, useGetItem, useSubmitCreate, useSubmitModify } from './hooksData.js';


const fieldDefs = {
  productType : {
    initial : 0,
    label : 'Product Type',
    type : 'radio',
    options : ['Chassis', 'Body', 'Miscellaneous'],
  },
  duty : {
    initial : 0,
    label : 'Duty Type',
    type : 'radio',
    options : ['Carrier', 'Light Duty', 'Heavy Duty'],
    condItem : 'productType',
    condValues : [0,1],
  },
  orderType : {
    initial : 0,
    label : 'Order Type',
    type : 'radio',
    options : ['Stock', 'Show', 'Sold'],
  },
  customer : {
    initial : '',
    label : 'Customer',
    type : 'text',
    condItem : 'orderType',
    condValues : [2],
    validation : Yup.string().when('orderType', {
      is: (val) => val === 2,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().notRequired()
    }),
  },
  vendor : {
    initial : '',
    label : 'Vendor',
    type : 'text',
    validation: Yup.string()
      .required('Required'),
  },
  description : {
    initial : '',
    label : 'Description',
    type : 'text',
    validation: Yup.string()
      .required('Required'),
  },
  price : {
    label : 'Price',
    type : 'number',
    validation: Yup.number()
      .required('Required'),
  },
  received : {
    label : 'Received',
    type : 'check',
  },
  voided : {
    label : 'Void',
    type : 'check',
  },
};


const fieldDefsNote = {
  note : {
    initial : '',
    label : 'Note',
    type : 'text',
    validation: Yup.string()
      .required('Required'),
  },
};


const fieldDefsFile = {
  description : {
    initial : '',
    label : 'File Description',
    type : 'text',
    validation: Yup.string()
      .required('Required'),
  },
};


const viewDefs = {
  table : {
    component : PurchaseOrdersTable,
  },
  view : {
    component : PurchaseOrderView,
    back : 'table',
  },
  create : {
    component : PurchaseOrderCreate,
    back : 'table',
  },
  modify : {
    component : PurchaseOrderModify,
    back : 'view',
  },
  note : {
    component : PurchaseOrderNote,
    back : 'view',
  },
  upload : {
    component : PurchaseOrderFile,
    back : 'view',
  },
};


const filterDefs = {
  productType : {
    initialValue : -1,
    filterTest : (value,item) => {
      if (value === -1) return true;
      return value === item.productType;
    },
  },
  duty : {
    initialValue : -1,
    filterTest : (value,item) => {
      if (value === -1) return true;
      return value === item.duty;
    },
  },
  multiFilter : {
    initialValue : -1,
    filterTest : (value,item) => {
      if (value === -1) return true;
      return value === item.orderType;
    },
  },
};


function PurchaseOrders(props) {
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);

  const view = useViews(viewDefs,'table');
  const [filters,filterTest] = useFilters(filterDefs);

  const dataTable = useGetCollection('purchaseOrders',props.store,year);
  dataTable.data = dataTable.data.filter(filterTest);
  const dataItem = useGetItem('purchaseOrders',view.id);

  const Component = view.component;

  return (
    <Component 
      store={props.store} 
      year={year}
      yearChange={setYear}
      view={view}
      filters={filters}
      dataTable={dataTable}
      dataItem={dataItem} />
  );
}


function PurchaseOrdersTable(props) {
  const user = useContext(UserContext).user;

  const onClick = (event,id) => {
    event.preventDefault();
    props.view.change('view',id);
  }

  console.log(props.dataTable);

  const columns = [
    {
      Header: 'Number',
      accessor: 'number',
      Cell: ({ cell }) => 
        // eslint-disable-next-line
        <a href='#' onClick={(e) => onClick(e,cell.row.original.id)}>
          {cell.row.original.voided ? cell.value + ' VOID' : cell.value}
        </a>
    },
    {
      Header: 'SO Number',
      accessor: 'soNumber',
      Cell: ({ cell }) => 
        // eslint-disable-next-line
        <>
          {cell.value === -1 ? 'N/A' : cell.value}
        </>
    },
    {
      Header: 'Status',
      accessor: 'status'
    },
    {
      Header: 'Date',
      accessor: 'date'
    },
    {
      Header: 'Expected',
      accessor: 'completeStatus',
      Cell: ({ cell }) =>
        <>
          {cell.value === 0 &&
            <>TBD</>
          }
          {cell.value === 1 &&
            <>{cell.row.original.completeDate}</>
          }
          {cell.value === 2 &&
            <>CRD</>
          }
        </>
    },
    {
      Header: 'Order Type',
      accessor: 'orderType',
      Cell: ({ cell }) =>
        <>
          {cell.value === 2 &&
            <>{cell.row.original.customer}</>
          }
          {cell.value !== 2 &&
            <>{fieldDefs.orderType.options[cell.value]}</>
          }
        </>
    },
    {
      Header: 'Vendor',
      accessor: 'vendor'
    },
    {
      Header: 'Description',
      accessor: 'description'
    },
    {
      Header: 'Price',
      accessor: 'price',
      Cell: ({ cell }) => <>{cell.value.toFixed(2)}</>
    },
  ];

  return (
    <>
      <BannerStore label="Purchase Orders" store={props.store} />
      <div className="d-flex flex-column flex-md-row">
        { user['purchaseOrder'+props.store] &&
          <Button onClick={() => props.view.change('create')} className="mt-2 mr-5">
            Create New
          </Button>
        }
        <DropdownYear
          className="mt-2 mr-auto" 
          defaultValue={props.year}
          onChange={props.yearChange}/>
        <DropdownFilter
          className="mt-2 mr-5"
          label="Type"
          defaultValue={props.filters.productType.value}
          options={fieldDefs.productType.options}
          onChange={props.filters.productType.change}/>
        <DropdownFilter
          className="mt-2 mr-5"
          label="Duty"
          defaultValue={props.filters.duty.value}
          options={fieldDefs.duty.options}
          onChange={props.filters.duty.change}/>
        <DropdownFilter
          className="mt-2"
          label="Filter"
          defaultValue={props.filters.multiFilter.value}
          options={fieldDefs.orderType.options}
          onChange={props.filters.multiFilter.change}/>
      </div>
      { props.dataTable.loading &&
        <LoadingSpin/>
      }
      { props.dataTable.error &&
        <p className="mt-2 mt-3">Error loading table data.</p>
      }
      { !props.dataTable.loading && !props.dataTable.error &&
        <ReactTable columns={columns} data={props.dataTable.data} />
      }
    </>
  );
}


function PurchaseOrderView(props) {
  const user = useContext(UserContext).user;

  if (props.dataItem.error) {
    props.dataItem.back();
  }

  const price = typeof(props.dataItem.data.price) === 'number' ? 
    props.dataItem.data.price.toFixed(2) : null;
  
  let files = null;
  if (Array.isArray(props.dataItem.data.files)) {
    files = props.dataItem.data.files.map((file,index) => 
      <p key={index}>On<strong> {file.date}</strong>, 
        <strong> {file.creator} </strong> 
        uploaded: 
        <a 
          href={'/api/files/'+file.filename+file.fileExtension}
          download={props.dataItem.data.number+' '+file.description+file.fileExtension}>
          {' '+props.dataItem.data.number+' '+file.description+file.fileExtension}
        </a>
      </p>
    );
  }

  let notes = null;
  if (Array.isArray(props.dataItem.data.notes)) {
    notes = props.dataItem.data.notes.map((note,index) => 
      <p key={index}>On<strong> {note.date}</strong>, 
        <strong> {note.creator}</strong> noted, "{note.note}"
      </p>
    );
  }

  return (
    <>
      <BannerStore label="Purchase Order for" store={props.store} />
      {props.dataItem.loading && <LoadingSpin/>}
      {!props.dataItem.loading &&
        <>
          { props.dataItem.data.voided &&
            <p className="mt-3 text-danger"><strong>Purchase Order VOID</strong></p>
          }
          <p className="mt-3">
            Purchase Order 
            <strong> {props.dataItem.data.number}</strong> created by
            <strong><> </> 
              {props.dataItem.data.creator.substring(0, props.dataItem.data.creator.lastIndexOf("@"))}
            </strong> on 
            <strong> {props.dataItem.data.date}</strong>.
          </p>
          <p>
            Status: <strong>{props.dataItem.data.status}</strong>
          </p>
          <p>
            {props.dataItem.data.completeStatus === 0 &&
              <>Expected: <strong>TBD</strong></>
            }
            {props.dataItem.data.completeStatus === 1 &&
              <>Expected: <strong>{props.dataItem.data.completeDate}</strong></>
            }
            {props.dataItem.data.completeStatus === 2 &&
              <>Expected: <strong>CRD</strong></>
            }
          </p>
          <p>
            Sales Order Number: 
            <strong>{' '}
              {props.dataItem.data.soNumber === -1 ? 'Not Assigned' : props.dataItem.data.soNumber}
            </strong>
          </p>
          <p>
            Product Type: 
            <strong> {fieldDefs.duty.options[props.dataItem.data.duty]}</strong>
            <strong> {fieldDefs.productType.options[props.dataItem.data.productType]}</strong>
          </p>
          <p>Order Type: <strong>{fieldDefs.orderType.options[props.dataItem.data.orderType]}</strong></p>
          { props.dataItem.data.orderType === 2 &&
            <p>Customer: <strong>{props.dataItem.data.customer}</strong></p>
          }
          <p>Vendor: <strong>{props.dataItem.data.vendor}</strong></p>
          <p>Description: <strong>{props.dataItem.data.description}</strong></p>
          <p>Price: <strong>{price}</strong></p>
          <p>Received: 
            <strong> {props.dataItem.data.received ? 'Yes' : 'No' }</strong>
          </p>
          { user['purchaseOrder'+props.store] &&
            <>
              <Button className="mr-2" onClick={() => props.view.change('modify',props.id)}>Modify</Button>
            </>
          }
          <Button onClick={props.view.back}>Back</Button>
          <hr/>
          <p className='mt-3'>Files:</p>
          {files}
          { user['purchaseOrder'+props.store] &&
            <Button onClick={() => props.view.change('upload',props.id)}>Add File</Button>
          }
          <hr/>
          <p className='mt-3'>Notes:</p>
          {notes}
          { user['purchaseOrder'+props.store] &&
            <Button onClick={() => props.view.change('note',props.id)}>Add Note</Button>
          }
        </>
      }
    </>
  );
}


function PurchaseOrderCreate(props) {
  const [submit,success] = useSubmitCreate('purchaseOrders',props.store);

  useEffect(() => {
    if (success) {
      props.dataItem.refresh();
      props.dataTable.refresh();
      props.view.back();
    }
  });

  return (
    <>
      <BannerStore label='Create Purchase Order for' store={props.store}/>
      <FormController
        fields={fieldDefs}
        submit={submit}
        submitLabel='Create'
        back={props.view.back}/>
    </>
  );
}


function PurchaseOrderModify(props) {
  const [submit,success] = useSubmitModify('purchaseOrders',props.view.id,props.store);

  useEffect(() => {
    if (success) {
      props.dataItem.refresh();
      props.dataTable.refresh();
      props.view.back();
    }
  });

  if (props.dataItem.error) props.view.back();

  return (
    <>
      <BannerStore label='Modify Purchase Order for' store={props.store}/>
      {props.dataItem.loading && <LoadingSpin/>}
      {!props.dataItem.loading &&
        <FormController
          fields={fieldDefs}
          submit={submit}
          submitLabel='Update'
          back={props.view.back}
          data={props.dataItem.data}/>
      }
    </>
  );
}


function PurchaseOrderNote(props) {
  const [submit,success] = useSubmitCreate('purchaseOrderNotes',props.store);
  const submitWithId = (values, { setSubmitting }) => 
    submit({ purchaseOrder_id : props.view.id, ...values }, { setSubmitting });

  useEffect(() => {
    if (success) {
      props.dataItem.refresh();
      props.dataTable.refresh();
      props.view.back();
    }
  });

  return (
    <>
      <BannerStore label='Add Note to Purchase Order for' store={props.store}/>
      <FormController
        fields={fieldDefsNote}
        submit={submitWithId}
        submitLabel='Add'
        back={props.view.back}/>
    </>
  );
}


function PurchaseOrderFile(props) {
  const [submit,success] = useSubmitCreate('purchaseOrderFiles',props.store);
  const submitWithId = (values, { setSubmitting }) => 
    submit({ purchaseOrder_id : props.view.id, ...values }, { setSubmitting });

  useEffect(() => {
    if (success) {
      props.dataItem.refresh();
      props.dataTable.refresh();
      props.view.back();
    }
  });

  return (
    <>
      <BannerStore label='Add Note to Purchase Order for' store={props.store}/>
      <FormController
        fields={fieldDefsFile}
        submit={submitWithId}
        submitLabel='Add'
        back={props.view.back}
        upload={true}/>
    </>
  );
}


const viewDefsSync = {
  upload : {
    component : PurchaseOrdersSyncUpload,
  },
  compare : {
    component : PurchaseOrdersSyncCompare,
  },
  create : {
    component : PurchaseOrdersSyncCreate,
    back : 'compare'
  },
  rename : {
    component : PurchaseOrdersRenameCreate,
    back : 'compare'
  },
  renameMod : {
    component : PurchaseOrdersRenameModify,
    back : 'compare'
  },
};


function PurchaseOrdersSync(props) {
  const [orderStatus,setOrderStatus] = useState('open');
  const [mode,setMode] = useState('mindates');
  const view = useViews(viewDefsSync,'upload');
  const dataTable = useGetCollection('purchaseOrdersSync',props.store);

  const Component = view.component;

  return (
    <Component 
      store={props.store} 
      view={view}
      orderStatus={orderStatus}
      setOrderStatus={setOrderStatus}
      mode={mode}
      setMode={setMode}
      dataTable={dataTable} />
  );
}


function PurchaseOrdersSyncUpload(props) {
  const [submit,success,reset] = useSubmitCreate('purchaseOrdersSync',props.store);
  const submitWithExtra = (values, { setSubmitting }) => 
    submit({ status : props.orderStatus , ...values }, { setSubmitting });

  useEffect(() => {
    if (props.dataTable.data !== null) props.view.change('compare');
    if (success) {
      reset();
      props.dataTable.refresh();
    }
  });

  return (
    <>
      <BannerStore label='Sync Orders for' store={props.store}/>
      {props.dataTable.loading && <LoadingSpin/>}
      {!props.dataTable.loading &&
        <>
          <DropdownSelect
            className="mt-2 mr-5 mb-2"
            label="Order Status"
            defaultValue={props.orderStatus}
            options={{
              open : 'Open',
              closed : 'Closed',
              cancelled : 'Cancelled'
            }}
            onChange={props.setOrderStatus}/>
          <FormController
            fields={{}}
            submit={submitWithExtra}
            submitLabel='Process'
            upload={true}
            uploadLabel='Upload Excel File'/>
        </>
      }
    </>
  );
}


function PurchaseOrdersSyncCompare(props) {
  const [del,success,reset] = useDelete('purchaseOrdersSync',props.store)
  const [submit,successSubmit,resetSubmit] = useSubmitCreate('purchaseOrderKills',props.store);
  const [delUnkill,successUnkill,resetUnkill] = useDeleteItem('purchaseOrderKills/'+props.store)
  const [delRename,successRename,resetRename] = useDeleteItem('purchaseOrderRenames/'+props.store)
  const [submitUpdate,successUpdate,resetUpdate] = useSubmitModify('PurchaseOrdersSync',null,props.store);
  const submitKill = (number) => {
    return submit({soNumber:number},{setSubmitting:(value) => null});
  };
  const submitUpdateExtra = (id,variable,value) => {
    let temp = {};
    temp['id'] = id;
    temp[variable] = value;
    return submitUpdate(temp,{setSubmitting:(value) => null});
  };

  useEffect(() => {
    if (props.dataTable.data === null) props.view.change('upload');
    if (success) {
      reset();
      props.dataTable.refresh();
    }
    if (successSubmit) {
      resetSubmit();
      props.dataTable.refresh();
    }
    if (successUnkill) {
      resetUnkill();
      props.dataTable.refresh();
    }
    if (successRename) {
      resetRename();
      props.dataTable.refresh();
    }
    if (successUpdate) {
      resetUpdate();
      props.dataTable.refresh();
    }
  });

  let data;
  if ( props.dataTable.data === null || Array.isArray(props.dataTable.data) ) {
    data = [];
  } else if ( props.mode === 'mindates' ) {
    data = props.dataTable.data.mindates.map((item,index) =>
      <div className="d-flex mt-2 align-items-center" key={index}>
        <Button onClick={() => submitUpdateExtra(item.id,'date',item.mindate)}>
          Update
        </Button>
        <pre className="mb-0">
          <b>   {item.mindate}   {item.date}</b>   {item.number}   {item.status}   {item.description}   {item.price}
        </pre>
      </div>
    );
  } else if ( props.mode === 'duplicates' ) {
    data = props.dataTable.data.duplicates.map((item,index) =>
      <div className="d-flex mt-2 align-items-center" key={index}>
        {item.id === -1 &&
          <>
            <Button className="mr-2" onClick={() => submitKill(item.soNumber)}>Kill</Button>
            <Button onClick={() => props.view.change('rename',item)}>Rename</Button>
          </>
        }
        {item.id !== -1 &&
          <>
            <Button onClick={() => submitUpdateExtra(item.id,'soNumber',-1)}>Unassign</Button>
          </>
        }
        <pre className="mb-0">
          <b>   {item.soNumber}   {item.number}</b>   {item.status}   {item.date}   {item.description}   {item.price}
        </pre>
      </div>
    );
  } else if ( props.mode === 'notAssigned' ) {
    data = props.dataTable.data.notAssigned.map((item,index) =>
      <div key={index}>
        <div className="d-flex mt-2 align-items-center">
          <Button onClick={() => submitUpdateExtra(item.id,'soNumber',item.soNumberNew)}>
            Assign
          </Button>
          <pre className="mb-0">
            <b>   {item.soNumber}   {item.number}</b>   {item.status}   {item.date}   {item.description}   {item.price}
          </pre>
        </div>
        <pre className="mb-0">
          <b>   {item.soNumberNew}   {item.numberNew}</b>   {item.statusNew}   {item.dateNew}   {item.descriptionNew}   {item.priceNew}
        </pre>
      </div>
    );
  } else if ( props.mode === 'modified' ) {
    data = props.dataTable.data.diffs.map((item,index) =>
      <div className="d-flex mt-2 align-items-center" key={index}>
        <Button onClick={() => submitUpdateExtra(item.id,item.variable,item.miller)}>
          Update Crouch
        </Button>
        <pre className="mb-0">
          <b>   {item.number}   {item.variable}</b>   Crouch: {item.crouch}   Miller: {item.miller}
        </pre>
      </div>
    );
  } else if ( props.mode === 'missingMiller' ) {
    data = props.dataTable.data.missingMiller.map((item,index) =>
      <pre key={index}>
        <b>{item.number}</b>   {item.status}   {item.date}   {item.description}   {item.price}
      </pre>
    );
  } else if ( props.mode === 'missingCrouch' ) {
    data = props.dataTable.data.missingCrouch.map((item,index) =>
      <div className="d-flex mt-2 align-items-center" key={index}>
        <Button className="mr-2" onClick={() => props.view.change('create',index)}>Add</Button>
        <Button className="mr-2" onClick={() => submitKill(item.soNumber)}>Kill</Button>
        <Button onClick={() => props.view.change('rename',item)}>Rename</Button>
        <pre className="mb-0">
          <b>   {item.soNumber}   {item.number}</b>   {item.status}   {item.date}   {item.description}   {item.price}
        </pre>
      </div>
    );
  } else if ( props.mode === 'killed' ) {
    data = props.dataTable.data.killed.map((item,index) =>
      <div className="d-flex mt-2 align-items-center" key={index}>
        <Button onClick={() => delUnkill(item.soNumber)}>Unkill</Button>
        <pre className="mb-0">
          <b>   {item.soNumber}   {item.number}</b>   {item.status}   {item.date}   {item.description}   {item.price.toFixed(2)}
        </pre>
      </div>
    );
  } else if ( props.mode === 'renames' ) {
    data = props.dataTable.data.renames.map((item,index) =>
      <div className="d-flex mt-2 align-items-center" key={index}>
        <Button className="mr-2" onClick={() => props.view.change('renameMod',item)}>Modify</Button>
        <Button onClick={() => delRename(item.soNumber)}>Delete</Button>
        <pre className="mb-0">
          <b>   {item.soNumber}</b>   {item.number_old} -> {item.number}
        </pre>
      </div>
    );
  }

  return (
    <>
      <BannerStore label='Sync Orders for' store={props.store}/>
      {props.dataTable.loading && <LoadingSpin/>}
      {!props.dataTable.loading &&
        <>
          <div className="d-flex flex-column flex-md-row">
            <DropdownSelect
              className="mt-2 mr-5"
              label="Mode"
              defaultValue={props.mode}
              options={{
                mindates : 'Minimum Date',
                duplicates : 'Duplicates',
                notAssigned : 'Not Assigned',
                modified : 'Modified',
                missingMiller : 'Missing from Miller',
                missingCrouch : 'Missing from Crouch',
                killed : 'Killed',
                renames : 'Renames',
              }}
              onChange={props.setMode}/>
            <Button onClick={del} className="mt-2 mr-5">
              Cancel
            </Button>
          </div>
          <div className="mt-3">
            {data}
          </div>
        </>
      }
    </>
  );
}


const fieldDefsSync = {
  productType : {
    initial : 0,
    label : 'Product Type',
    type : 'radio',
    options : ['Chassis', 'Body', 'Miscellaneous'],
  },
  duty : {
    initial : 0,
    label : 'Duty Type',
    type : 'radio',
    options : ['Carrier', 'Light Duty', 'Heavy Duty'],
    condItem : 'productType',
    condValues : [0,1],
  },
  orderType : {
    initial : 0,
    label : 'Order Type',
    type : 'radio',
    options : ['Stock', 'Show', 'Sold'],
  },
  customer : {
    initial : '',
    label : 'Customer',
    type : 'text',
    condItem : 'orderType',
    condValues : [2],
    validation : Yup.string().when('orderType', {
      is: (val) => val === 2,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().notRequired()
    }),
  },
  received : {
    label : 'Received',
    type : 'check',
  },
  voided : {
    label : 'Void',
    type : 'check',
  },
};


function PurchaseOrdersSyncCreate(props) {
  const [submit,success] = useSubmitCreate('purchaseOrdersSync/create',props.store);
  const dataItem = props.dataTable.data.missingCrouch[props.view.id];
  const submitWithExtra = (values, { setSubmitting }) => 
    submit({ ...dataItem, ...values }, { setSubmitting });

  useEffect(() => {
    if (success) {
      props.dataTable.refresh();
      props.view.back();
    }
  });

  return (
    <>
      <BannerStore label='Create Purchase Order for' store={props.store}/>
      <p className="mb-2">
        Description: <strong>{dataItem.description}</strong>
      </p>
      <FormController
        fields={fieldDefsSync}
        submit={submitWithExtra}
        submitLabel='Create'
        back={props.view.back}/>
    </>
  );
}


const fieldDefsRename = {
  number : {
    label : 'New Number',
    type : 'text',
    validation: Yup.string()
      .required('Required'),
  },
};


function PurchaseOrdersRenameCreate(props) {
  const [submit,success] = useSubmitCreate('purchaseOrderRenames',props.store);
  const dataItem = props.view.id;
  const submitWithExtra = (values, { setSubmitting }) => 
    submit({ soNumber : dataItem.soNumber , ...values }, { setSubmitting });

  useEffect(() => {
    if (success) {
      props.dataTable.refresh();
      props.view.back();
    }
  });

  return (
    <>
      <BannerStore label='Rename Purchase Order for' store={props.store}/>
      <FormController
        fields={fieldDefsRename}
        submit={submitWithExtra}
        submitLabel='Rename'
        back={props.view.back}
        data={dataItem}/>
    </>
  );
}


function PurchaseOrdersRenameModify(props) {
  const [submit,success] = useSubmitModify('purchaseOrderRenames',null,props.store);
  const dataItem = props.view.id;
  const submitWithExtra = (values, { setSubmitting }) => 
    submit({ soNumber : dataItem.soNumber , ...values }, { setSubmitting });

  useEffect(() => {
    if (success) {
      props.dataTable.refresh();
      props.view.back();
    }
  });

  return (
    <>
      <BannerStore label='Rename Purchase Order for' store={props.store}/>
      <FormController
        fields={fieldDefsRename}
        submit={submitWithExtra}
        submitLabel='Rename'
        back={props.view.back}
        data={dataItem}/>
    </>
  );
}


export { PurchaseOrders, PurchaseOrdersSync };
