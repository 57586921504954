import React from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useGetCollection } from './hooksData.js';


function LoadingSpin(props) {
  return (
    <Spinner className="mt-3" animation="border" />
  );
}


function BannerStore(props) {
  const stores = { TN: 'Tennessee', FL: 'Florida' };
  return (
    <h4 className="mt-3">{props.label} {stores[props.store]}</h4>
  );
}


function DropdownSelect(props) {
  function handleChange(event) {
    props.onChange(event.target.value);
  }

  const options = Object.entries(props.options).map(([key,text]) =>
    <option value={key} key={key}>{text}</option>
  );

  return (
    <Form inline className={props.className}>
      <Form.Group>
        <Form.Label className="mr-2">{props.label}</Form.Label>
        <Form.Control defaultValue={props.defaultValue} onChange={handleChange} as="select">
          {options}
        </Form.Control>
      </Form.Group>
    </Form>
  );
}


function DropdownFilter(props) {
  function handleChange(event) {
    props.onChange(parseInt(event.target.value));
  }

  const options = props.options.map((name,index) =>
    <option value={index} key={index}>{name}</option>
  );

  return (
    <Form inline className={props.className}>
      <Form.Group>
        <Form.Label className="mr-2">{props.label}</Form.Label>
        <Form.Control defaultValue={props.defaultValue} onChange={handleChange} as="select">
          <option value={-1} key={-1}>All</option>
          {options}
        </Form.Control>
      </Form.Group>
    </Form>
  );
}


function DropdownStore(props) {
  function handleChange(event) {
    props.onChange(event.target.value);
  }

  return (
    <Form inline className={props.className}>
      <Form.Group>
        <Form.Label className="mr-2">Store</Form.Label>
        <Form.Control defaultValue={props.defaultValue} onChange={handleChange} as="select">
          <option value='TN'>Tennessee</option>
          <option value='FL'>Florida</option>
        </Form.Control>
      </Form.Group>
    </Form>
  );
}


function DropdownUser(props) {
  const users = useGetCollection('users');

  const options = users.data.map((name,index) =>
    <option value={name} key={index}>{name}</option>
  );

  function handleChange(event) {
    props.onChange(event.target.value);
  }

  return (
    <Form inline className={props.className}>
      <Form.Group>
        <Form.Label className="mr-2">{props.label}</Form.Label>
        { users.loading &&
          <>Loading...</>
        }
        { !users.loading &&
        <Form.Control defaultValue={props.defaultValue} onChange={handleChange} as="select">
          <option value="All" key={-1}>All</option>
          {options}
        </Form.Control>
        }
      </Form.Group>
    </Form>
  );
}


function DropdownYear(props) {
  function handleChange(event) {
    props.onChange(event.target.value);
  }

  const currentYear = new Date().getFullYear();
  const oldestYear = 2016;
  var years = [];
  var i;
  for (i = currentYear+1; i >= oldestYear; i--) {
    years.push(i);
  }
  const options = years.map((year) =>
    <option value={year} key={year}>{year}</option>
  );

  return (
    <Form inline className={props.className}>
      <Form.Group>
        <Form.Label className="mr-2">Year</Form.Label>
        <Form.Control defaultValue={props.defaultValue} onChange={handleChange} as="select">
          {options}
        </Form.Control>
      </Form.Group>
    </Form>
  );
}


export { LoadingSpin, BannerStore, DropdownSelect, DropdownFilter, DropdownStore, DropdownUser, DropdownYear }
