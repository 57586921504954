import { useReducer } from 'react';


function useViews(viewDefs,initialView) {
  const initialState = {
    view : initialView,
    id : 1,
  }

  function reducer(state, action) {
    return { ...state, view : action.view, id: action.id };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  function change(viewName,id = null) {
    if (id === null) return dispatch({ view: viewName, id: state.id });
    return dispatch({ view: viewName, id: id });
  }

  return { 
    name: state.view,
    id: state.id,
    component: viewDefs[state.view].component, 
    back: () => change(viewDefs[state.view].back),
    change: change,
  };
}


function useFilters(filterDefs) {
  let initialState = {};
  Object.keys(filterDefs).forEach((name) => {
    initialState[name] = filterDefs[name].initialValue;
  });

  function reducer(state, action) {
    return { ...state, [action.name] : action.value };
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  let filters = {};
  Object.keys(filterDefs).forEach((name) => {
    filters[name] = {};
    filters[name].value = state[name];
    filters[name].change = (value) => dispatch({ name: name, value: value});
  });

  const filterTests = Object.entries(filterDefs).map(([name,filter]) => 
    (item) => filter.filterTest(state[name],item));

  const filterTest = (item) => 
    filterTests.reduce((accumulator,filterTest) =>
      accumulator && filterTest(item),true);

  return [filters,filterTest];
}


export { useViews, useFilters };
