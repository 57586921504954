import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Button, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import axios from 'axios';
import UserContext from './UserContext.js';
import Login from './Login.js';
import { PurchaseOrders, PurchaseOrdersSync } from './PurchaseOrders.js';
import { Expenses, ExpensesAdmin } from './Expenses.js';
import Users from './Users.js';


const UserProvider = UserContext.Provider;


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username : '',
    }
    this.checkUser = this.checkUser.bind(this);
  }

  componentDidMount() {
    this.checkUser()
  }

  checkUser = async() => {
    let res = await axios.get('/api/session');
    let data = res.data;
    if (data.error) {
      this.setState({
        username: ''
      });
    } else {
      this.setState(data.data);
    }
  }

  render () {
    let context = {
      user : this.state,
      checkUser : this.checkUser
    };

    return (
      <UserProvider value={context}>
        {this.state.username === '' ? <Login/> : <Main/>}
      </UserProvider>
    );
  }
}


class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: ''
    };
    this.handleChangePage = this.handleChangePage.bind(this);
  }

  handleChangePage(page) {
    this.setState({ page: page });
  }

  render () {
    return (
      <>
        <UserBar/>
        <MainBar handleChangePage={this.handleChangePage}/>
        <Container>
          {this.state.page === 'purchaseOrderTN' && 
            <PurchaseOrders store='TN'/>
          }
          {this.state.page === 'purchaseOrderFL' && 
            <PurchaseOrders store='FL'/>
          }
          {this.state.page === 'purchaseOrderSyncTN' && 
            <PurchaseOrdersSync store='TN'/>
          }
          {this.state.page === 'purchaseOrderSyncFL' && 
            <PurchaseOrdersSync store='FL'/>
          }
          {this.state.page === 'expenses' && 
            <Expenses/>
          }
          {this.state.page === 'expensesAdmin' && 
            <ExpensesAdmin/>
          }
          {this.state.page === 'users' && 
            <Users/>
          }
        </Container>
      </>
    );
  }
}


class UserBar extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }

  static contextType = UserContext;

  handleLogout = async() => {
    let checkUser = this.context.checkUser;
    await axios.delete('/api/session');
    checkUser();
  }

  render() {
    let username = this.context.user.username;
    return (
      <Navbar bg="dark" variant="dark">
        <Container>
          <Navbar.Text className="mr-auto">{username}</Navbar.Text>
          <Button size="sm" onClick={this.handleLogout}>
            Sign Out
          </Button>
        </Container>
      </Navbar>
    );
  }
}


function MainBar(props) {
  const user = useContext(UserContext).user;
  return (
    <Navbar bg="light" variant="light">
      <Container>
        <Nav>
          <NavDropdown title="Purchase Orders">
            <NavDropdown.Item onClick={() => props.handleChangePage('purchaseOrderTN')}>
              Tennessee
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => props.handleChangePage('purchaseOrderFL')}>
              Florida
            </NavDropdown.Item>
            { user.admin &&
              <>
                <NavDropdown.Item onClick={() => props.handleChangePage('purchaseOrderSyncTN')}>
                  Sync Tennessee
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => props.handleChangePage('purchaseOrderSyncFL')}>
                  Sync Florida
                </NavDropdown.Item>
              </>
            }
          </NavDropdown>
          { (user.expenseTN || user.expenseFL) &&
            <NavDropdown title="Personal">
              <NavDropdown.Item onClick={() => props.handleChangePage('expenses')}>
                Expenses
              </NavDropdown.Item>
            </NavDropdown>
          }
          { user.admin &&
            <NavDropdown title="Admin">
              <NavDropdown.Item onClick={() => props.handleChangePage('users')}>
                Users
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => props.handleChangePage('expensesAdmin')}>
                Approve Expenses
              </NavDropdown.Item>
            </NavDropdown>
          }
        </Nav>
      </Container>
    </Navbar>
  );
}


export default App;
