import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import UserContext from './UserContext.js';


function Login() {
  return (
    <Container className="h-100">
      <Row className="h-100 align-items-center">
        <Col></Col>
        <Col xs={12} sm={9} md><LoginData/></Col>
        <Col></Col>
      </Row>
    </Container>
  );
}


class LoginData extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.username = React.createRef();
    this.password = React.createRef();
  }

  static contextType = UserContext;

  handleLogin = async(event) => {
    event.preventDefault();
    const checkUser = this.context.checkUser;
    const username = this.username.current.value;
    const password = this.password.current.value;
    let input = {
      username: username,
      password: password
    };
    let res = await axios.post('/api/session',input);
    let data = res.data;
    if (data.error) {
      alert(data.message);
    } else {
      checkUser();
    }
  }

  render() {
    return (
      <LoginBox 
        username={this.username}
        password={this.password}
        handleLogin={this.handleLogin} />
    );
  }
}


function LoginBox(props) {
  return (
    <Card bg="light">
      <Card.Body>
        <Card.Title>Crouch Portal</Card.Title>
        <Form onSubmit={props.handleLogin}>
          <Form.Group controlId="formUsername">
            <Form.Label>Username</Form.Label>
            <Form.Control type="email" ref={props.username} />
          </Form.Group>
          <Form.Group controlId="formPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" ref={props.password} />
          </Form.Group>
          <Button type="submit" block>
            Sign In
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}


export default Login;
