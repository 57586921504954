import React, { useState, useEffect, useContext } from 'react';
import { Navbar, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import ReactTable from './ReactTable.js';
import { FormController } from './componentsForm.js';
import { LoadingSpin, BannerStore, DropdownStore, DropdownUser, DropdownYear, DropdownFilter } from './componentsUtil.js';
import UserContext from './UserContext.js';
import { useViews, useFilters } from './hooksOther.js';
import { useGetCollection, useSubmitCreate, useDeleteItem } from './hooksData.js';


const fieldDefs = {
  expenseType : {
    label : 'Expense Type',
    type : 'radio',
    initial : 0,
    options : ['Card','Reimbursement'],
    userItem : 'reimbursement',
  },
  dateExpense : {
    label : 'Expense Date',
    type : 'date',
    initial : '',
  },
  category : {
    label : 'Category',
    type : 'dropdown',
    initial : 0,
    options : ['Travel','Customer Development','Transport','Business Development','Miscellaneous','Cost of Goods Sold','Special']
  },
  subcategory : {
    label : 'Subcategory',
    type : 'dropdown',
    initial : 0,
    options : [
      ['Meals','Lodging','Airfare','Fuel','Ground Transportation','Miscellaneous'],
      ['Meals','Lodging','Airfare','Fuel','Ground Transportation','Miscellaneous'],
      ['Meals','Lodging','Airfare','Fuel','Ground Transportation','Miscellaneous','Permits'],
      ['Meals','Lodging','Airfare','Fuel','Ground Transportation','Miscellaneous','Entertainment'],
      ['Supplies','Vehicle Repairs'],
      ['Repairs New','Repairs Used','Parts/Accessories','Other'],
      ['Housing Allowance','Relocation Costs'],
    ],
    condItem : 'category',
  },
  stockNumber : {
    label : 'Stock Number',
    type : 'text',
    initial : '',
    condItem : 'category',
    condValues : [5],
    validation : Yup.string().when('category', {
      is: (val) => val === 5,
      then: Yup.string().required('Required'),
      otherwise: Yup.string().notRequired()
    }),
  },
  description : {
    label : 'Description',
    type : 'text',
    initial : '',
    validation : Yup.string()
      .required('Required'),
  },
  value : {
    label : 'Value',
    type : 'number',
    initial : '',
    validation : Yup.number()
      .required('Required'),
  },
};


const statusOptions = ['Submitted','Approved','Processed','Disallowed'];


const viewDefs = {
  table : {
    component : ExpensesTable,
  },
  create : {
    component : ExpensesCreate,
    back : 'table',
  },
};


const viewDefsAdmin = {
  table : {
    component : ExpensesTableAdmin,
  },
};


const filterDefs = {
  expenseStatus : {
    initialValue : -1,
    filterTest : (value,item) => {
      if (value === -1) return true;
      return value === item.status;
    },
  }
};


const filterDefsAdmin = {
  creator : {
    initialValue : 'All',
    filterTest : (value,item) => {
      if (value === 'All') return true;
      return value === item.creator;
    },
  },
  expenseStatus : {
    initialValue : -1,
    filterTest : (value,item) => {
      if (value === -1) return true;
      return value === item.status;
    },
  },
};


function Expenses(props) {
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [store, setStore] = useState('TN');

  const view = useViews(viewDefs,'table');
  const [filters,filterTest] = useFilters(filterDefs);

  const dataTable = useGetCollection('expenses',year);
  dataTable.data = dataTable.data.filter(filterTest);

  const Component = view.component;

  return (
    <Component 
      store={store}
      storeChange={setStore}
      year={year}
      yearChange={setYear}
      view={view}
      filters={filters}
      dataTable={dataTable} />
  );
}


function ExpensesAdmin(props) {
  const currentYear = new Date().getFullYear();
  const [year, setYear] = useState(currentYear);
  const [store, setStore] = useState('TN');

  const view = useViews(viewDefsAdmin,'table');
  const [filters,filterTest] = useFilters(filterDefsAdmin);

  const dataTable = useGetCollection('expensesAdmin',store,year);
  dataTable.data = dataTable.data.filter(filterTest);

  const Component = view.component;

  return (
    <Component 
      store={store}
      storeChange={setStore}
      year={year}
      yearChange={setYear}
      view={view}
      filters={filters}
      dataTable={dataTable} />
  );
}


function ExpensesTable(props) {
  const user = useContext(UserContext).user;
  const [deleteItem,success,reset] = useDeleteItem('expenses');

  const onClick = (event,id) => {
    event.preventDefault();
    if (window.confirm("Delete expense?")) deleteItem(id);
  }

  function createNew(store) {
    props.storeChange(store);
    props.view.change('create');
  }

  useEffect(() => {
    if (success) {
      reset();
      props.dataTable.refresh();
    }
  });

  const columns = [
    {
      Header: 'Action',
      accessor: 'id',
      Cell: ({ cell }) => 
        <>
          { /* eslint-disable-next-line */ }
          <a href='#' onClick={(e) => onClick(e,cell.value)}>Delete</a>
          <> | </>
          <a 
            href={'/api/files/'+cell.row.original.filename+cell.row.original.fileExtension}
            target='_blank'
            rel='noopener noreferrer'>
            View
          </a>
        </>
    },
    {
      Header: 'Submitted',
      accessor: 'dateCreated'
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ cell }) => <>{statusOptions[cell.value]}</>
    },
    {
      Header: 'Store',
      accessor: 'store'
    },
    {
      Header: 'Date',
      accessor: 'dateExpense'
    },
    {
      Header: 'Type',
      accessor: 'expenseType',
      Cell: ({ cell }) => <>{fieldDefs.expenseType.options[cell.value]}</>
    },
    {
      Header: 'Category',
      accessor: 'category',
      Cell: ({ cell }) => <>
        {fieldDefs.category.options[cell.value]}
        <>: </>
        {fieldDefs.subcategory.options[cell.value][cell.row.original.subcategory]}
      </>
    },
    {
      Header: 'Description',
      accessor: 'description'
    },
    {
      Header: 'Value',
      accessor: 'value',
      Cell: ({ cell }) => <>{cell.value.toFixed(2)}</>
    },
  ];

  return (
    <>
      <BannerStore label="Expenses" />
      <div className="d-flex flex-column flex-md-row">
        { user.expenseTN &&
          <Button onClick={() => createNew('TN')} className="mt-2 mr-5">
            Create New {user.expenseTN && user.expenseFL && 'Tennessee'}
          </Button>
        }
        { user.expenseFL &&
          <Button onClick={() => createNew('FL')} className="mt-2 mr-5">
            Create New {user.expenseTN && user.expenseFL && 'Florida'}
          </Button>
        }
        <DropdownYear
          className="mt-2 mr-5" 
          defaultValue={props.year}
          onChange={props.yearChange}/>
        <DropdownFilter
          className="mt-2 mr-auto" 
          label="Status"
          defaultValue={props.filters.expenseStatus.value}
          options={statusOptions}
          onChange={props.filters.expenseStatus.change}/>
      </div>
      { props.dataTable.loading &&
        <LoadingSpin/>
      }
      { props.dataTable.error &&
        <p className="mt-3">Error loading table data.</p>
      }
      { !props.dataTable.loading && !props.dataTable.error &&
        <ReactTable columns={columns} data={props.dataTable.data} />
      }
    </>
  );
}


function ExpensesTableAdmin(props) {
  const columns = [
    {
      Header: 'Action',
      accessor: 'id',
      Cell: ({ cell }) => 
        <>
          <a 
            href={'/api/files/'+cell.row.original.filename+cell.row.original.fileExtension}
            target='_blank'
            rel='noopener noreferrer'>
            View
          </a>
        </>
    },
    {
      Header: 'Submitted',
      accessor: 'dateCreated'
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ cell }) => <>{statusOptions[cell.value]}</>
    },
    {
      Header: 'Creator',
      accessor: 'creator'
    },
    {
      Header: 'Store',
      accessor: 'store'
    },
    {
      Header: 'Date',
      accessor: 'dateExpense'
    },
    {
      Header: 'Type',
      accessor: 'expenseType',
      Cell: ({ cell }) => <>{fieldDefs.expenseType.options[cell.value]}</>
    },
    {
      Header: 'Category',
      accessor: 'category',
      Cell: ({ cell }) => <>
        {fieldDefs.category.options[cell.value]}
        <>: </>
        {fieldDefs.subcategory.options[cell.value][cell.row.original.subcategory]}
      </>
    },
    {
      Header: 'Description',
      accessor: 'description'
    },
    {
      Header: 'Value',
      accessor: 'value',
      Cell: ({ cell }) => <>{cell.value.toFixed(2)}</>
    },
  ];

  return (
    <>
      <BannerStore label="Approve Expenses" />
      <Navbar>
        <DropdownUser
          className="mr-5" 
          label="User"
          defaultValue={props.filters.creator.value}
          onChange={props.filters.creator.change}/>
        <DropdownStore
          className="mr-5" 
          defaultValue={props.store}
          onChange={props.storeChange}/>
        <DropdownYear
          className="mr-5" 
          defaultValue={props.year}
          onChange={props.yearChange}/>
        <DropdownFilter
          className="mr-auto" 
          label="Status"
          defaultValue={props.filters.expenseStatus.value}
          options={statusOptions}
          onChange={props.filters.expenseStatus.change}/>
      </Navbar>
      { props.dataTable.loading &&
        <LoadingSpin/>
      }
      { props.dataTable.error &&
        <p className="mt-3">Error loading table data.</p>
      }
      { !props.dataTable.loading && !props.dataTable.error &&
        <ReactTable columns={columns} data={props.dataTable.data} />
      }
    </>
  );
}


function ExpensesCreate(props) {
  const [submit,success] = useSubmitCreate('expenses',props.store);

  useEffect(() => {
    if (success) {
      props.dataTable.refresh();
      props.view.back();
    }
  });

  return (
    <>
      <BannerStore label='Create Expense' store={props.store} />
      <FormController
        fields={fieldDefs}
        submit={submit}
        submitLabel='Create'
        back={props.view.back}
        upload={true}
        uploadLabel='Receipt Upload'/>
    </>
  );
}


export { Expenses, ExpensesAdmin };
