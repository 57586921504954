import { useEffect, useReducer, useState } from 'react';
import axios from 'axios';


function useGetCollection(type,path1=null,path2=null) {
  const initialState = {
    data: [],
    loading: true,
    error: false,
    trigger: 1
  };

  const reducer = (state,action) => {
    switch (action.type) {
      case 'loading':
        return { ...state, loading: true };
      case 'error':
        return { ...state, 
          data: [],
          loading: false,
          error: true
        };
      case 'success':
        return { ...state,
          data: action.payload,
          loading: false,
          error: false
        };
      case 'trigger':
        return { ...state, trigger: state.trigger + 1 };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const getData = async() => {
      dispatch({ type: 'loading' });
      let url;
      let path1Url = '';
      let path2Url = '';
      if (path1 !== null) path1Url = '/' + path1;
      if (path2 !== null) path2Url = '/' + path2;
      url = '/api/' + type + path1Url + path2Url;
      let res = await axios.get(url);
      res = res.data;
      if (res.error) {
        alert(res.message);
        dispatch({ type: 'error' });
      } else {
        dispatch({ type: 'success', payload: res.data });
      }
    }
    getData();
  }, [type,path1,path2,state.trigger]);

  const triggerUpdate = () => dispatch({ type: 'trigger' });

  return { 
    data : state.data,
    loading : state.loading,
    error : state.error,
    refresh : triggerUpdate,
  };
}


function useGetItem(type,id) {
  const initialState = {
    data: {},
    loading: true,
    error: false,
    trigger: 1
  };

  const reducer = (state,action) => {
    switch (action.type) {
      case 'empty':
        return { ...state,
          data: {},
          loading: false,
          error: false
        };
      case 'loading':
        return { ...state, loading: true };
      case 'error':
        return { ...state, 
          data: {},
          loading: false,
          error: true
        };
      case 'success':
        return { ...state,
          data: action.payload,
          loading: false,
          error: false
        };
      case 'trigger':
        return { ...state, trigger: state.trigger + 1 };
      default:
        throw new Error();
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const getData = async(lookupId) => {
      dispatch({ type: 'loading' });
      let url = '/api/' + type + '/' + lookupId;
      let res = await axios.get(url);
      res = res.data;
      if (res.error) {
        alert(res.message);
        dispatch({ type: 'error' });
      } else {
        dispatch({ type: 'success', payload: res.data });
      }
    };
    if ( id === null ) {
      dispatch({ type: 'empty' });
    } else {
      getData(id);
    }
  }, [type,id,state.trigger]);

  const triggerUpdate = () => dispatch({ type: 'trigger' });

  return { 
    data : state.data,
    loading : state.loading,
    error : state.error,
    refresh : triggerUpdate,
  };
}


function useSubmitCreate(type,store=null) {
  const [success, setSuccess] = useState(false);

  const submit = async(values, { setSubmitting }) => {
    setSuccess(false);
    let url = '/api/' + type;
    let input = { ...values };
    if ( store !== null ) input.store = store;
    let res = await axios.post(url,input);
    res = res.data;
    setSubmitting(false);
    if (res.error) {
      alert(res.message);
    } else {
      setSuccess(true);
    }
  };

  const reset = () => setSuccess(false);

  return [submit,success,reset];
}


function useSubmitModify(type,id=null,store=null) {
  const [success, setSuccess] = useState(false);

  const submit = async(values, { setSubmitting }) => {
    setSuccess(false);
    let url = '/api/' + type;
    let input = { ...values };
    if ( 'id' in values ) id = values.id;
    if ( id !== null ) url = url + '/' + String(id);
    if ( store !== null ) input.store = store;
    let res = await axios.patch(url,input);
    res = res.data;
    setSubmitting(false);
    if (res.error) {
      alert(res.message);
    } else {
      setSuccess(true);
    }
  };

  const reset = () => setSuccess(false);

  return [submit,success,reset];
}


function useDelete(type,store) {
  const [success, setSuccess] = useState(false);

  const del = async() => {
    setSuccess(false);
    let url = '/api/' + type + '/' + store;
    let res = await axios.delete(url);
    res = res.data;
    if (res.error) {
      alert(res.message);
    } else {
      setSuccess(true);
    }
  };

  const reset = () => setSuccess(false);

  return [del,success,reset];
}


function useDeleteItem(type) {
  const [success, setSuccess] = useState(false);

  const deleteItem = async(id) => {
    setSuccess(false);
    let url = '/api/' + type + '/' + String(id);
    let res = await axios.delete(url);
    res = res.data;
    if (res.error) {
      alert(res.message);
    } else {
      setSuccess(true);
    }
  };

  const reset = () => setSuccess(false);

  return [deleteItem,success,reset];
}


export { useGetCollection, useGetItem, useDelete, useDeleteItem, useSubmitCreate, useSubmitModify };
