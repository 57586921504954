import React, { useState, useEffect } from 'react';
import { useTable, usePagination, useSortBy } from 'react-table';
import { Table, Form, Pagination } from 'react-bootstrap';
 

function ReactTable({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy,
    usePagination,
  )

  // Render the UI for your table
  return (
    <>
      <PageChange
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={pageCount}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        setPageSize={setPageSize}/>
      <Table className="mb-0 mt-2" bordered {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' ▼'
                        : ' ▲'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>
      <PageChange
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageCount={pageCount}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        setPageSize={setPageSize}/>
    </>
  )
}


function PageChange(props) {
  const [page,setPage] = useState(props.pageIndex+1);

  function handleChange(event) {
    setPage(event.target.value);
    const number = Number(event.target.value);
    if ( number >= 1 && number <= props.pageCount ) props.gotoPage(number-1);
  }

  useEffect(() => {
    setPage(props.pageIndex+1);
  },[props.pageIndex]);

  return (
    <div className="d-flex align-items-center justify-content-end mt-2">
      <Form inline className="mr-2">
        <Form.Group>
          <Form.Label className="mr-2">
            Show
          </Form.Label>
          <Form.Control 
            value={props.pageSize} 
            onChange={e => props.setPageSize(Number(e.target.value))}
            as="select">
            <option value='10'>10</option>
            <option value='100'>100</option>
            <option value='1000'>1000</option>
            <option value='10000'>10000</option>
          </Form.Control>
        </Form.Group>
      </Form>
      <Pagination className="mr-2 mb-0">
        <Pagination.First 
          onClick={() => props.gotoPage(0)} 
          disabled={!props.canPreviousPage} />
        <Pagination.Prev
          onClick={() => props.previousPage()} 
          disabled={!props.canPreviousPage} />
      </Pagination>
      <Form.Control 
        value={page} 
        onChange={handleChange}
        type="number"
        className="mr-2"
        style={{ width: 80 }}/>
      <div className="mr-2">of {props.pageCount}</div>
      <Pagination className="mb-0">
        <Pagination.Next
          onClick={() => props.nextPage()} 
          disabled={!props.canNextPage}/>
        <Pagination.Last 
          onClick={() => props.gotoPage(props.pageCount - 1)} 
          disabled={!props.canNextPage}/>
      </Pagination>
    </div>
  );
}


export default ReactTable;
