import React, { useEffect } from 'react';
import { Navbar, Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { FormController } from './componentsForm.js';
import { LoadingSpin, BannerStore } from './componentsUtil.js';
import { useViews } from './hooksOther.js';
import { useGetCollection, useSubmitCreate, useSubmitModify } from './hooksData.js';


const fieldDefs = {
  username : {
    initial : '',
    label : 'Username',
    type : 'text',
    validation : Yup.string()
      .required('Required'),
  },
  password : {
    initial : '',
    label : 'Password',
    type : 'text',
    validation : Yup.string()
      .required('Required'),
  },
};


const usernameDefs = {
  username : {
    label : 'Username',
    type : 'text',
    validation : Yup.string()
      .required('Required'),
  },
};


const passwordDefs = {
  password : {
    initial : '',
    label : 'Password',
    type : 'text',
    validation : Yup.string()
      .required('Required'),
  },
};


const permissionDefs = {
  admin : {
    label : 'Admin',
    type : 'check',
  },
  purchaseOrderTN : {
    label : 'Create Purchase Orders Tennessee',
    type : 'check',
  },
  purchaseOrderFL : {
    label : 'Create Purchase Orders Florida',
    type : 'check',
  },
  expenseTN : {
    label : 'Create Expenses Tennessee',
    type : 'check',
  },
  expenseFL : {
    label : 'Create Expenses Florida',
    type : 'check',
  },
  expenseAdminTN : {
    label : 'Admin Expenses Tennessee',
    type : 'check',
  },
  expenseAdminFL : {
    label : 'Admin Expenses Florida',
    type : 'check',
  },
  reimbursement : {
    label : 'Reimbursement of Expenses',
    type : 'check',
  },
};


const viewDefs = {
  table : {
    component : UsersTable,
  },
  create : {
    component : UserCreate,
    back : 'table',
  },
  username : {
    component : UserUsername,
    back : 'table',
  },
  password : {
    component : UserPassword,
    back : 'table',
  },
  permission : {
    component : UserPermissions,
    back : 'table',
  },
};


function Users(props) {
  const view = useViews(viewDefs,'table');
  const dataTable = useGetCollection('usersAdmin');
  const dataItem = dataTable.data.find(item => item.id === view.id);

  const Component = view.component;

  return (
    <Component 
      view={view}
      dataTable={dataTable}
      dataItem={dataItem}/>
  );
}


function UsersTable(props) {
  const options = props.dataTable.data.map((item) => {
    return (
    <p key={item.id}>
      <Button size="sm" variant="link" 
        onClick={() => props.view.change('permission',item.id)}>
        Change Permissions
      </Button>
      <Button size="sm" variant="link" 
        onClick={() => props.view.change('username',item.id)}>
        Change Username
      </Button>
      <Button size="sm" variant="link" 
        onClick={() => props.view.change('password',item.id)}>
        Change Password
      </Button>
      <>    </>{item.username}
    </p>
    );
  });

  return (
    <>
      <BannerStore label="User Management" />
      <Navbar>
        <Button onClick={() => props.view.change('create')}>
          Create
        </Button>
      </Navbar>
      { props.dataTable.loading &&
        <LoadingSpin/>
      }
      { props.dataTable.error &&
        <p className="mt-3">Error loading table data.</p>
      }
      { !props.dataTable.loading && !props.dataTable.error &&
        <div className="mt-3">{options}</div>
      }
    </>
  );
}


function UserCreate(props) {
  const [submit,success] = useSubmitCreate('usersAdmin');

  useEffect(() => {
    if (success) {
      props.dataTable.refresh();
      props.view.back();
    }
  });

  return (
    <>
      <BannerStore label='Create User' />
      <FormController
        fields={fieldDefs}
        submit={submit}
        submitLabel='Create'
        back={props.view.back} />
    </>
  );
}


function UserUsername(props) {
  const [submit,success] = useSubmitModify('usersAdmin',props.view.id);

  useEffect(() => {
    if (success) {
      props.dataTable.refresh();
      props.view.back();
    }
  });

  return (
    <>
      <BannerStore label='Change Username' />
      <FormController
        fields={usernameDefs}
        submit={submit}
        submitLabel='Update'
        back={props.view.back}
        data={props.dataItem} />
    </>
  );
}


function UserPassword(props) {
  const [submit,success] = useSubmitModify('usersAdmin',props.view.id);

  useEffect(() => {
    if (success) {
      props.view.back();
    }
  });

  return (
    <>
      <BannerStore label='Change Password' />
      <FormController
        fields={passwordDefs}
        submit={submit}
        submitLabel='Update'
        back={props.view.back} />
    </>
  );
}


function UserPermissions(props) {
  const [submit,success] = useSubmitModify('usersAdmin',props.view.id);

  useEffect(() => {
    if (success) {
      props.dataTable.refresh();
      props.view.back();
    }
  });

  return (
    <>
      <BannerStore label='Change Permissions' />
      <FormController
        fields={permissionDefs}
        submit={submit}
        submitLabel='Update'
        back={props.view.back}
        data={props.dataItem} />
    </>
  );
}


export default Users;
