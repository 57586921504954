import React, { useState, useEffect, useContext } from 'react';
import { useFormikContext, useField } from 'formik';
import { Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import UserContext from './UserContext.js';


const CheckInput = ({ label, ...props }) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);

  const { value } = meta;
  const { setValue } = helpers;

  function handleChange(event) {
    setValue(event.target.checked);
  }

  return (
    <Form.Check checked={value}
      onChange={handleChange} type="checkbox" label={label}/>
  );
}


const DateInput = ({ label, ...props }) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);

  const { setValue } = helpers;

  const [date,setDate] = useState(new Date());

  function handleChange(date) {
    setDate(date);
    setValue({
      year : date.getFullYear(),
      month : date.getMonth()+1,
      day : date.getDate()
    });
  }

  // eslint-disable-next-line
  useEffect(() => handleChange(date),[]);

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <br/>
      <DatePicker
        selected={date}
        onChange={handleChange} 
        className='form-control'/>
    </Form.Group>
  );
}


const DropdownInput = ({ label, condItem, ...props }) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);
  const { values } = useFormikContext();

  const { value } = meta;
  const { setValue } = helpers;

  function handleChange(event) {
    setValue(parseInt(event.target.value));
  }

  let options;
  if (condItem === undefined) {
    options = props.options.map((name,index) =>
      <option value={index} key={index}>{name}</option>);
  } else {
    options = props.options[values[condItem]].map((name,index) =>
      <option value={index} key={index}>{name}</option>);
  }

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control defaultValue={value} as="select" onChange={handleChange}>
        {options}
      </Form.Control>
    </Form.Group>
  );
}


const RadioInput = ({ label, condItem, condValues, userItem, ...props }) => {
  // eslint-disable-next-line
  const [field, meta, helpers] = useField(props);
  const { values } = useFormikContext();
  const user = useContext(UserContext).user;

  const { value } = meta;
  const { setValue } = helpers;

  let disabled;
  if ( condItem === undefined || condValues === undefined ) {
    disabled = false;
  } else {
    disabled = condValues.includes(values[condItem]) ? false : true;
  }
  if ( userItem !== undefined && !user[userItem] ) disabled = true;
  if (disabled) return null;

  function handleChange(event) {
    setValue(parseInt(event.target.value));
  }

  const options = props.options.map((name,index) => 
    <Form.Check checked={value === index} value={index} key={index}
      onChange={handleChange} type="radio" label={name} className="mr-2"/>
  );

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Row>
        {options}
      </Form.Row>
    </Form.Group>
  );
}


const TextInput = ({ label, condItem, condValues, ...props }) => {
  const [field, meta] = useField(props);
  const { values } = useFormikContext();

  let disabled;
  if ( condItem === undefined || condValues === undefined ) {
    disabled = false;
  } else {
    disabled = condValues.includes(values[condItem]) ? false : true;
  }
  if (disabled) return null;

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control isInvalid={meta.touched && meta.error} {...field} {...props}/>
      <Form.Control.Feedback type="invalid">
        {meta.error}
      </Form.Control.Feedback>
    </Form.Group>
  );
};


export { CheckInput, DateInput, DropdownInput, RadioInput, TextInput };
